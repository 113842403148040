import Menu from "../components/Menu";

function ContainerPage({page}) {
    return ( 
    <div className="centerAlign column container" style={{minHeight: "100vh", height: "fit-content"}}>
        <Menu anim={page ? true : false}/>
        <div className={page ? "fadeIn" : "fadeOut"} style={{position: "absolute", top: "30vh", height: "auto"}}>
    {page}
    </div>
    </div>
     );
}

export default ContainerPage;