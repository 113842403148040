import { useEffect, useState } from 'react';
import { getProblems } from './scripts/dataHandler';
import { BrowserRouter } from "react-router-dom";
import "./styles/main.css"
import MainPage from './pages/MainPage2';

function App() {

  let [problems,setProblems] = useState([])

  useEffect(() => {
    getProblems(setProblems)
  }, [])

  return (
  
      <BrowserRouter>
        <MainPage problems={problems}/>
        </BrowserRouter>
    
  );
}

export default App;
