import { Link } from "react-router-dom";

function Menu({anim}) {
    //let animClass = anim ? "hoverUp" : "hoverDown"
    let animClass = anim ? " abs" : ""
    /*let style = anim ? {position: "absolute", top: "5vh"} : {}*/

  return (
      
      <div className={"menu centerAlign column" + animClass} enabled={(anim ? true : false).toString()} style={{zIndex: "5"}}>
        <Link to="/">
      <h1 style={{fontSize: "max(3.2vw,3.3vh)"}}>Archiv soutěže ŠMouLa</h1>
      </Link>
      <div className="row">
        {/*<Link to="/historie">
          <div className="menuItem centerAlign row">
            <p className="">Historie</p>
          </div>
        </Link>*/}

        <Link to="/ulohy?rocnik=1">
          <div className="menuItem centerAlign row">
            <p className="">Úlohy</p>
          </div>
        </Link>
        </div>
      </div>
  );
}

export default Menu;
