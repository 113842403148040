import { Route, Routes, useSearchParams } from "react-router-dom";
import ProblemPage from './ProblemPage';
import ContainerPage from "./ContainerPage";
import HistoryPage from "./HistoryPage";
import ProblemInfoPage from "./ProblemInfoPage";

function MainPage({problems}) {

    const [searchParams] = useSearchParams();

  return (
    <div
      className="centerAlign column container"
      style={{ width: "100vw", overflowX: "hidden"}}
    >
        
        <Routes>
            <Route path="/" element={<ContainerPage page={undefined}/>} />
            <Route path="/historie" element={<ContainerPage page={<HistoryPage/>} />} />
            <Route path="/ulohy" element={<ContainerPage page={<ProblemPage problemsRaw={problems} season={searchParams.get("rocnik")}/>} />} />
            <Route path="/uloha" element={<ContainerPage page={<ProblemInfoPage problems={problems} problemId={searchParams.get("id")}/>} />} />
        </Routes>
      
      
    </div>
  );
}

export default MainPage;
