
import { Link } from "react-router-dom";
function TreeNode({problem,state}) {
    const style = {"position": "absolute", "top": 0, "left:": 0,"transform": "translate(" + (problem.xPos-0.2) +  "vw," + (problem.yPos - 2.5) + "vh)"};

    const activeClass = state || "disabled"
    const classes = "treeNode centerAlign " + activeClass

    console.log(problem)

    return ( 
        
        <div style={style}>
            <Link to={"/uloha?id=" + problem.id}>
        <div className={classes}>
            <h2>{problem.id}</h2>
        </div>
        </Link>
        </div>
     );
}

export default TreeNode;