import { Link } from "react-router-dom";

function SeasonBtn({season}) {
    return ( 
        <Link to={"/ulohy?rocnik=" + season}>
        <div className="seasonBtn">
            {season}
        </div>
        </Link>
     );
}

export default SeasonBtn;