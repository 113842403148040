//const url = "https://vast-watch-412319.ey.r.appspot.com/";
//const url = "http://localhost:8080/"
const url = "https://omega-post-416123.ey.r.appspot.com/"

export const getProblems = (setProblems) => {
  fetch(url + "getData/problemsArchive")
    .then((res) => {
      return res.json();
    })
    .then((data) => {
      setProblems(data.problems);
      return
    });
};


export const getTeams = (setTeams) => {
  fetch(url + "getData/teamsFull")
    .then((res) => {
      return res.json();
    })
    .then((data) => {
      setTeams(data.teams)
    });
};
