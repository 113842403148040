function ProblemInfoPage({problems, problemId}) {

    const getProblem = (id) => {
        return problems.filter((problem) => {
            return problem.id == id
        })
    }

    let [problem] = getProblem(problemId)
    if (!problem) {
      problem = {}
    } 

    const getDesc = (text) => {
        if (text && text.length > 1000) {
            return text.slice(0,1000) + "..."
        } else if (!text || text == "")
        {return "xxx"} else {return text}
    }

    const getAnswer = (text) => {
        let parts = text.split("<br>")
        return parts[parts.length-1]
    }

    return (
         <div className="problemInfoPage column">

            {problem.solution ? <div style={{width: "80vw"}}>
            <h1>{`Úloha ${problem.id}: ${problem.title}`}</h1>
            <div className="row" style={{width: "80vw"}}>
                <h2 style={{marginRight: "2vw"}}>Zadání:</h2>
                <div className="column">
                <p dangerouslySetInnerHTML={{__html:getDesc(problem.description)}} style={{marginTop: "3.5vh"}}></p>
                <p>{problem.note}</p>
                </div>
            </div>

            <div className="centerAlign">
            {problem.img && <img src={problem.img} style={{width: "40vw", margin: "5vh 0vw",}} alt={"Obrázek úlohy " + problem.title}/>}
            </div>

            <div className="row solutionContainer" style={{width: "80vw", marginBottom: "5vh"}}>
                <h2 style={{marginRight: "2vw"}}>Řešení:</h2>
                <p dangerouslySetInnerHTML={{__html:problem.solution}} style={{marginTop: "3.5vh"}}></p>
            </div>
            </div> : <><h1>Tato úloha neexistuje</h1></>}
    </div> 
    );
}

export default ProblemInfoPage;