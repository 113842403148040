import SeasonBtn from "./SeasonBtn";

function SeasonPicker({problems}) {
    let seasons = []
    problems.forEach(problem => {
        if (!seasons.includes(problem.season)) {
            seasons.push(problem.season)
        }
    });

    const mapSeasonBtns = (seasons) => {
        return seasons.map((ssn) => {
            return <SeasonBtn season={ssn}/>
        })
    }
    return ( 
        <div className="column centerAlign">
            <h2>Ročník:</h2>
    <div className="centerAlign row">
        {mapSeasonBtns(seasons)}
    </div> 
    </div>
    );
}

export default SeasonPicker;