import Line from "../components/Line";
import SeasonPicker from "../components/SeasonPicker";
import TreeNode from "../components/TreeNode";


function ProblemPage({problemsRaw, season}) {

  let problems = problemsRaw.filter((problem) => problem.season == season)

    const mapProblems = (problemData) => {
        return problemData.map((problem) => {
          return (
           
            <TreeNode
              problem={problem}
              state={"active"}
              key={problem.id}
            />
          );
        });
      };
    
      const mapLines = (problemData) => {
        let lines = [];
        problemData.map((problem) => {
          if (!problem.childrenIds) {
            return <></>;
          }
          let i = 0;
          return problem.childrenIds.map((childId) => {
            i += 1;
            const child = problemData[childId];
            lines.push(
              <Line
                x1={problem.xPos + 50 + "vw"}
                y1={problem.yPos + "vh"}
                x2={child.xPos + 50 + "vw"}
                y2={child.yPos + "vh"}
                key={i}
              />
            );
            return <></>;
          });
        });
        return lines;
      };

    return ( <>
    <SeasonPicker problems={problemsRaw}/>
    <div className="gameContainer" style={{height: "200vh"}}>
      
      
        {problems.length ? <div style={{ marginLeft: "10vw"}}>
        <div className="centerAlign treeContainer" style={{ width: "80vw" }}>
          <div style={{ position: "relative" }}>
            <svg
              width="100vw"
              height="150vh"
              style={{ transform: "translate(0vw,2vh)", overflow: "hidden" }}
            >
              {problems && mapLines(problems)}
            </svg>
          </div>
          {problems && mapProblems(problems)}
        </div>
      </div> : <div style={{width: "100vw"}} className="centerAlign"><h1>Zatím žádné úlohy z tohoto ročníku nejsou dostupné, zkuste to později</h1></div>}
      </div>
    </> );
}

export default ProblemPage;